/****   serve.js   ****/
// 导入axios
import axios from 'axios';
import router from '@/router/index'
import Dialog from '@/components/mode/message';
import loading from '@/components/mode/loading'

import { Encrypt, Decrypt } from "@/utils/aes.js";
const urlList = [//需要添加加载动画的页面
  {url:'v1/user/getFans'},
  {url:'v1/user/getCollect'},
  {url:'v1/user/getComment'},
  {url:'v1/user/getFocus'},
  {url:'v1/user/getFollows'},
  {url:'v1/user/getBlacklist'},
  {url:'v1/user/getAdviceList'},
  {url:'v1/complaint/list'},
  {url:'v1/game/getList'},
  // {url:'v1/index/picture'},
  {url:'v1/discovery/list'},
  {url:'v1/user/getGrowthScore'},
  {url:'v1/diagram/list'},
  {url:'v1/forecast/list'},
  {url:'v1/corpus/infoArticle'},
  {url:'v1/corpus/listCorpusType'},
  {url:'v1/corpus/listArticle'},
  {url:'v1/picture/detail'},
  {url:'v1/picture/cate'},
  {url:'v1/humorous/detail'},
  {url:'v1/login/index'},
  {url:'v1/login/register'},
  {url:'v1/comment/create'},
  {url:'v1/forecast/create'},
  {url:'v1/plat/user_plat'},
  {url:'v1/plat/bind'},
  {url:'v1/diagram/create'},
  {url:'v1/discuss/create'},
  {url:'v1/room/list'},
  {url:'v1/user/addAdvice'},
  {url:'v1/user/editUserPass'},
  {url:'v1/user/setFundPassword'},
  {url:'v1/user/editFundPassword'},
  {url:'v1/plat/recharge'},
  {url:'v1/plat/withdraw'},
  {url:'v1/user/signIn'},
  {url:'v1/user_welfare/receive'},
  {url:'v1/user/records'},
  {url:'v1/login/mobileSend'},
  {url:'v1/graph/verify'},
  {url:'v1/mobile/send'},
  {url:'v1/mobile/verify'},
  {url:'v1/login/mobileLogin'},
  {url:'v1/forecast/bet'},
  {url:'v1/ky/login'},
  {url:'v1/dag/login'},
  {url:'v1/imone/getLaunchURLHTML'},
  {url:'v1/pg/getLaunchURLHTML'}
  //v1/user_welfare/receive
]

const configUrl = [//需要固定的api接口
  // {url:'v1/room/join'},
  // {url:'v1/room/chat'},
  // {url:'v1/login/index'},
  // {url:'v1/login/register'},
  // {url:'v1/login/captcha'},
  // {url:'v1/login/loginChat'},
  // {url:'v1/discovery/create'},
  // {url:'v1/comment/create'},
  // {url:'v1/discuss/create'},
  // {url:'v1/common/config'},
  // {url:'v1/graph/verify'},
  // {url:'v1/mobile/verify'},
  // {url:'v1/login/mobileLogin'},
  // {url:'v1/mobile/send'},
  // {url:'v1/imone/getLaunchURLHTML'},
  // {url:'v1/ky/login'},
  // {url:'v1/dag/login'},
 
]
// const immobilizations = (src)=>{
//   var url = ''
//   configUrl.forEach(item=>{
//     if(item.url==src){
//       url=item.url
//     }
//   })
//   return url
// }

const serveloading = (src)=>{
  var url = ''
  urlList.forEach(item=>{
    if(item.url==src){
      url=item.url
    }
  })
  return url
}
// 使用element-ui Message做消息提醒
import { showToast } from 'vant';
//1. 创建新的axios实例，

const service = axios.create({
  // 公共接口--这里注意后面会讲
  baseURL: process.env.VUE_APP_BASE_API+'/api',
  // 超时时间 单位是ms，这里设置了3s的超时时间
  timeout: 30 * 1000,
})
// 2.请求拦截器
service.interceptors.request.use(config => {
  //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
  //  config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
  // let configUrls
  // let configUrlsss
  // if (configUrlMain) {
  //   configUrls = JSON.parse(JSON.stringify(configUrlMain));
  //   configUrlsss = JSON.parse(JSON.stringify(configUrlMain));
  // } else {
  //   configUrls = JSON.parse(localStorage.getItem('configUrlBase'));
  //   configUrlsss = JSON.parse(localStorage.getItem('configUrlBase'));
  // }
  // var arr3 = JSON.parse(JSON.stringify(configUrls));
  // var arr2 =JSON.parse(JSON.stringify(configUrls));
  // if (arr2.length != 1) {
  //   arr3.shift();
  //   arr3.pop();
  //   if (Math.round(Math.random())) {
  //     var arr1 = arr2.sort(function() {
  //       return .4 - Math.random();
  //     })
  //     var b = arr1.slice(0,1);
  //   } else {
  //     var arr1 = arr3.sort(function() {
  //       return .4 - Math.random();
  //     });
  //     var b = arr1.slice(0,1);
  //   }

  // } else {
  //   var b = arr2
  // }
  config.baseURL =configAddBase.main_url+'/api';
  // if(config.url == immobilizations(config.url)){

  // }else{
  //   config.baseURL = b[0]+'/api';
  // }
   //如有需要：注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
   let token=localStorage.getItem('Authorization');//这里取token之前，你肯定需要先拿到token,存一下
   if(token){
      // config.params = {'token':token} //如果要求携带在参数中
      config.headers.Authorization= token; //如果要求携带在请求头中
    }
    if (config.url == serveloading(config.url) ) {
      loading()//请求开始
    }
    config.headers.Encipher= 'enable'; //如果要求携带在请求头中
    if(config.method == "post") {
      if(config.data) {
        config.data ={data:Encrypt(JSON.stringify(config.data))} ;
      }
    }
    return config
}, error => {
  Promise.reject(error)
})
// 3.响应拦截器
service.interceptors.response.use(response => {
  if (response.config.url == serveloading(response.config.url)) {
    loading('close')//请求开始
  }
  //接收到响应数据并成功后的一些共有的处理，关闭loading等
   // if(config.params) {
    //   config.params = Encrypt(  JSON.stringify(config.params) );
    // }
  if(response.data.status == 20000 && typeof(response.data.data) == "string") {
      if( response.data.list ) {
        response.data.list =  new Function('return ' + `${Decrypt(response.data.list)}`)() ;
      }
      response.data.data =  new Function('return ' + `${Decrypt(response.data.data)}`)() ;
  }
  return response
}, error => {
    // 没有网络时 message的内容为"Network Error"
  if( error.code == undefined|| error.code=='ECONNABORTED'){
      showToast('网络超时');
      loading('close')
      return false
  }
  let status = error.response.data.status;
  if (error.config.url == serveloading(error.config.url)) {
    loading('close')//请求开始
  }
  if (status==40009) {
    Dialog('系统维护中.....');
  }
  if(status==70006 || status==70005 || status==70004 || status==70003){//未登陆
      localStorage.removeItem('Authorization');
      localStorage.removeItem('usetInfo');
      if(error.config.url!='v1/user/getUserInfo'){//判断是否是主页是主页就不跳转登陆
        router.push('/login')
      }
  }
  /***** 处理结束 *****/
  //如果不需要错误处理，以上的处理过程都可省略
  return Promise.resolve(error.response)
})
//4.导入文件
export default service
