import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible/flexible.js'
import { Lazyload } from 'vant';
import '@vant/touch-emulator';
import 'video.js/dist/video-js.css';
import {config,yearAll,advertisementList,years,lottery_years} from '@/utils/https/page';
import {nextLottery,domain} from '@/utils/https/https';
import { number_attr } from "@/utils/https/statistics";
import component from '@/utils/component.js'// 引入公共组件
var arr = [];
var errorArray = [];
import axios from 'axios';
if (!configUrlMain) {
    configUrlMain = localStorage.getItem('configUrlBase');
    if (configUrlMain == null) {
        configUrlMain = [
            'https://api1.49tkapi8.com:8443',
            'https://api4.49tkapi8.com:8443',
            'https://api2.49tkapi8.com:8443',
            'https://api.49tkapi8.com:8443',
            'https://api3.49tkapi8.com:8443'
        ];
    }
}
var oneMain = JSON.parse(JSON.stringify(configAddBase.main_url));
var towMain = JSON.parse(JSON.stringify(configAddBase.main_url_backup));
var threeMain = JSON.parse(JSON.stringify(configAddBase.main_url_backup2));
//第一个域名    
const createImage = async () => {
    // for(let i =0;i<configUrlMain.length;i++) {
        axios.get(configUrlMain[0]+'/empty.json?'+Date.now()).then(res=>{
            arr.push(configUrlMain[0])
        }).catch(err=>{
            if(configUrlMain[0] == configAddBase.main_url) {
                configAddBase.main_url = towMain;
            }
            errorArray.push(configUrlMain[0]);
        })
    // }
}
//第二个域名
const createImages = async () => {
    // for(let i =0;i<configUrlBackup.length;i++) {
        axios.get(configUrlBackup[0]+'/empty.json?'+Date.now()).then(res=>{
            arr.push(configUrlBackup[0]);
        }).catch(err=>{
            if(configUrlBackup[0] == configAddBase.main_url_backup) {
                configAddBase.main_url = threeMain;
            }
            errorArray.push(configUrlBackup[0]);
        })
    // }
}
//第三个域名
const createImagess = async () => {
    // for(let i =0;i<configUrlBackup2.length;i++) {
        axios.get(configUrlBackup2[0]+'/empty.json?'+Date.now()).then(res=>{
            arr.push(configUrlBackup2[0]);
        }).catch(err=>{
            if(configUrlBackup2[0] == configAddBase.main_url_backup) {
                configAddBase.main_url = oneMain;
            }
            errorArray.push(configUrlBackup2[0]);
        })
    // }
}
function unique (arr) {
    return Array.from(new Set(arr))
}
const domains = () => {
    let from = {device:'1',domain:unique(errorArray)};
    axios.post(configAddBase.reporting_url+'/api/v1/statistics/domain',from, {headers: {"Content-Type": "multipart/form-data;charset=utf-8"}}).then(res=>{})
}
createImage();
let mains = ()=>{
    document.getElementById('appscc').style.display = "none";
    const app = createApp(App)
    app.use(router);
    app.config.globalProperties.$sayHello = () => { 
        // let avList = store.state.advertisementList;
        let avList = JSON.parse(localStorage.getItem('avList'));
        if(localStorage.getItem('lotteryType') == null){
            localStorage.setItem('lotteryType',2);
            localStorage.setItem('lotteryTypes',2);
        }
        if(sessionStorage.getItem('times') == null){
            sessionStorage.setItem('times', Date.now() + 1000 * 60 * 60 *2);
        }
        if (!isWithinTimeRange()) {
            advertisementList({type:2}).then(res=>{
                if(res.data.status==20000){
                    let adlist = res.data.data;
                    let adlistData = {adlist,tiems: Date.now() + 1000 * 60 * 30 }
                  
                    localStorage.setItem('avList',JSON.stringify(adlistData));
                    localStorage.setItem('avDesc',JSON.stringify(res.data.list));
                }
            })   
        }
   
        if(avList==null || avList.tiems < Date.now()){
            years().then(res=>{
                let c = res.data.data;
                localStorage.setItem('yearColor',JSON.stringify(c))
            })
        }
        if(sessionStorage.getItem("yearList")==null) {
            lottery_years().then(res=>{
                if(res.data.status == 20000) {
                    sessionStorage.setItem("yearList",JSON.stringify(res.data.data))
                }
            })
        }
        let number_attrs = JSON.parse(localStorage.getItem('number_attr'));
        if (number_attrs == null) {
            number_attr().then(res=>{
                if(res.data.status == 20000){
                    let datas = res.data.data;
                    localStorage.setItem('number_attr',JSON.stringify(datas))
                }
            })
        }
        if( localStorage.getItem('yearone')==null){
            yearAll().then(res=>{   
                if(res==undefined) return false
                let data = res.data.data;
                let reversed = data.reverse();
                localStorage.setItem('year', JSON.stringify(reversed));
                localStorage.setItem('yearone', reversed[0])
            })
        } 
        // localStorage.setItem('lotteryType',5)
    }
    app.config.globalProperties.$filters = {
        format(value) {

            if (value>=10000) {
                let num = value/10000
                return num.toFixed(1) + '万'
                
            } else {
                return value
            }
        }
    }
    app.config.globalProperties.$httpsUrl = localStorage.getItem('config') != null? JSON.parse(localStorage.getItem('config')).host2+'/' :"https://49tk.tuku.fit/";
    // 注册时设置`lazyComponent`选项
    app.use(Lazyload, {
    lazyComponent: true,
    });
    app.use(store);
    // app.use(loading);
    app.use(component);
    app.mount('#app');
}
const date = new Date();
const day = `${date.getFullYear()}/${date.getMonth()}/${date.getDate()}`
var toDays = localStorage.getItem('ping');
let timer=null;
let count=0;
const handleTnterval = () => {
    timer=setInterval(()=>{
        if(arr.length > 3 && includeMain(arr)){
            clearInterval(timer)
            // 安卓打包用                                                                                                                          q
            // localStorage.setItem('configUrlBase',JSON.stringify(configAppUrl))
            // configUrlMain = configAppUrl;
            localStorage.setItem('configUrlBase',JSON.stringify(arr))
            configUrlMain = arr;
            mains();
            localStorage.setItem('ping',day)
        }else{
           if(count % 3 == 0) {
            createImages();
           }
           if(count % 3 == 1) {
            createImagess();
           }
           if(count % 3 == 2) {
            createImage();
           }
            if(count>=6){
                clearInterval(timer)
                document.getElementById('tipss').innerHTML = "暂无可用路线，请更换网络或联系客服";
                document.getElementById('kfs').style.display = "block";
            }
        }
        count++
    },2000)
}
if (day == toDays) {
    console.log(version,localStorage.getItem('version'))
    // h5 打包用
    if(version == localStorage.getItem('version')) {
        configUrlMain = JSON.parse(localStorage.getItem('configUrlBase'));
    }else {
        handleTnterval();
        localStorage.setItem('version',version);
    }
    // 安卓打包用
    // configUrlMain = configAppUrl;
    mains();
} else {
    localStorage.setItem('version',version);
    handleTnterval();
    setTimeout(()=>{
        if(errorArray.length !=0){
            domains()
        }
    },15000)
    
}
const includeMain = (data) => {
    let result = data.includes(configAddBase.main_url);
    return result;
}

function isWithinTimeRange() {
    const currentTime = new Date();
    const hours = currentTime.getHours(); // 获取当前小时
    const minutes = currentTime.getMinutes(); // 获取当前分钟
  
    // 判断是否在 21:20 到 21:50 之间
    if ((hours === 21 && minutes >= 20) || (hours === 21 && minutes <= 50)) {
        return true;
    }
  
    return false;
}


