import { createStore } from 'vuex';
import { config } from '@/utils/https/page';
import { nextLottery } from '@/utils/https/https';
export default createStore({
  state: {
    lotteryType: localStorage.getItem('lotteryType') == null ? 2 : localStorage.getItem('lotteryType'),
    msg: '0',
    release: false,
    openLotteryList: [],
    advertisementList: [],
    search: true,
    config: JSON.parse(localStorage.getItem('config')),
    nextLottery: [],//下一期开奖时间
  },
  getters: {
  },
  mutations: {
    upLotteryCont(state, num) {
      state.lotteryType = num;
    },
    upvideoCont(state, num) {
      state.videoList = num;
    },
    msgadd(state, num) {
      state.msg = num;
    },
    exidRelease(state, num) {
      state.release = num;
    },
    handleLottery(state, num) {
      state.openLotteryList = num
    },
    handleAdvertisemen(state, num) {
      state.advertisementList = num
    },
    handleSearch(state, num) {
      state.search = num
    },
    handleConfig(state, num) {
      state.config = num
    },
    handleConfigFun(state) {
      function hasHttpOrHttps(url) {
          const regex = /^https?:\/\//; // 匹配 http:// 或 https://
          return regex.test(url);
      }
      if (isWithinTimeRange()) {
        if (!localStorage.getItem('config')) {
          config().then(res => {
            let datas = res.data.data
            state.config = res.data.data
            localStorage.setItem('config', JSON.stringify(datas))
            document.title = '49图库'
            document.title = datas.name
            var head = document.getElementsByTagName('head')[0];
            // 获取当前 favicon 元素
            var favicon = document.querySelector("link[rel*='icon']") || document.createElement('link');
            favicon.type = 'image/x-icon';
            favicon.rel = 'shortcut icon';
            // 设置新的 favicon 地址
            favicon.href =hasHttpOrHttps(datas.ad_img_url)?datas.ad_img_url  + datas.logo: "https://"+datas.ad_img_url  + datas.logo

            // 如果当前 head 标签中不存在 favicon 元素，则将新的 favicon 添加到 head 标签中
            if (!document.querySelector("link[rel*='icon']")) {
              head.appendChild(favicon);
            }
          })
        } else {
          let datas = JSON.parse(localStorage.getItem('config'))
          state.config = datas
          document.title = '49图库'
          document.title = datas.name
          var head = document.getElementsByTagName('head')[0];
          // 获取当前 favicon 元素
          var favicon = document.querySelector("link[rel*='icon']") || document.createElement('link');
          favicon.type = 'image/x-icon';
          favicon.rel = 'shortcut icon';
          // 设置新的 favicon 地址
          favicon.href =hasHttpOrHttps(datas.ad_img_url)?datas.ad_img_url  + datas.logo: "https://"+datas.ad_img_url  + datas.logo

          // 如果当前 head 标签中不存在 favicon 元素，则将新的 favicon 添加到 head 标签中
          if (!document.querySelector("link[rel*='icon']")) {
            head.appendChild(favicon);
          }
        }

      } else {
        config().then(res => {
          let datas = res.data.data
          state.config = res.data.data
          localStorage.setItem('config', JSON.stringify(datas))
          document.title = '49图库'
          document.title = datas.name
          var head = document.getElementsByTagName('head')[0];
          // 获取当前 favicon 元素
          var favicon = document.querySelector("link[rel*='icon']") || document.createElement('link');
          favicon.type = 'image/x-icon';
          favicon.rel = 'shortcut icon';
          // 设置新的 favicon 地址
          favicon.href =hasHttpOrHttps(datas.ad_img_url)?datas.ad_img_url  + datas.logo: "https://"+datas.ad_img_url  + datas.logo

          // 如果当前 head 标签中不存在 favicon 元素，则将新的 favicon 添加到 head 标签中
          if (!document.querySelector("link[rel*='icon']")) {
            head.appendChild(favicon);
          }
        })
      }

    },
    handleNextLottery(state) {
      if (isWithinTimeRange()) {
        let datas = localStorage.getItem('nextLottery')
        if (datas) {
          state.nextLottery = JSON.parse(localStorage.getItem('nextLottery'))
        } else {
          nextLottery().then(res => {//获取下一期的开奖数据
            if (res.status != 500) {
              let datas = res.data.data;
              state.nextLottery = datas
              localStorage.setItem('nextLottery', JSON.stringify(datas))
            }
          })
        }

      } else {
        nextLottery().then(res => {//获取下一期的开奖数据
          if (res.status != 500) {
            let datas = res.data.data;
            state.nextLottery = datas
            localStorage.setItem('nextLottery', JSON.stringify(datas))
          }
        })
      }

    },
  },
  actions: {
    getDeviceUsageInfo({ commit }) {
      commit('handleConfigFun')
    },
    getDeviceNextLottery({ commit }) {
      commit('handleNextLottery')
    }
  },
  modules: {
  }
})
function isWithinTimeRange() {
  const currentTime = new Date();
  const hours = currentTime.getHours(); // 获取当前小时
  const minutes = currentTime.getMinutes(); // 获取当前分钟

  // 判断是否在 21:20 到 21:50 之间
  if ((hours === 21 && minutes >= 20) || (hours === 21 && minutes <= 50)) {
    return true;
  }

  return false;
}
